import { usePathname, useRouter } from 'next/navigation';
import { useDropdown } from '@/hooks/useDropdown';
import type { IUseFilterSort } from '@/components/RootComponents/Category/modules/FilterBar/types';
import { convertObjectToSearchParams } from '@/components/RootComponents/Category/modules/FilterBlock/utils/helpers';

export const useFilterSort = ({ handleScrollToFilterBar, options, search, startTransition }: IUseFilterSort) => {
    const router = useRouter();
    const pathname = usePathname();
    const searchParams = convertObjectToSearchParams(search);
    const sortParams = searchParams ? searchParams.get('sort') : '';

    const [sortParamsAttribute, sortParamsDirection] = sortParams?.split('-') || [];

    const { elementRef: orderElementRef, expanded: isSortOpen, setExpanded: setIsSortOpen } = useDropdown();

    const handleItemClick = (sortAttribute: { attribute: string; sortDirection: string }) => {
        const nextParams = convertObjectToSearchParams(search);

        nextParams?.set('p', '1');
        nextParams?.set('sort', `${sortAttribute.attribute}-${sortAttribute.sortDirection}`);

        startTransition(() => router.push(pathname + '?' + nextParams?.toString(), { scroll: false }));

        setIsSortOpen(false);
        handleScrollToFilterBar();
    };

    const handleOpenCloseSortBlock = () => {
        setIsSortOpen(!isSortOpen);
    };

    const currentSort = options?.find(
        ({ attribute, sortDirection }) => attribute === sortParamsAttribute && sortDirection === sortParamsDirection,
    );

    return {
        currentSort,
        handleItemClick,
        handleOpenCloseSortBlock,
        isSortOpen,
        orderElementRef,
    };
};
