import { useCallback, useMemo } from 'react';
import type { IPageControl } from '../types';

export const usePagination = ({ currentPage, setPage, totalPages }: IPageControl) => {
    const handleNavBack = useCallback(
        (pageIndex = currentPage) => {
            if (pageIndex > 1) {
                setPage?.(pageIndex - 1);
            }
        },
        [currentPage, setPage],
    );

    const handleNavForward = useCallback(() => {
        if (currentPage < totalPages) {
            setPage?.(currentPage + 1);
        }
    }, [currentPage, setPage, totalPages]);

    const isActiveLeft = currentPage !== 1;
    const isActiveRight = currentPage !== totalPages;

    // generate pages
    const tiles = useMemo(() => {
        // start with first page
        let pages = [1];

        // add rest of the pages (some invalid)
        pages.push(currentPage - 2, currentPage - 1, currentPage, currentPage + 1, currentPage + 2, totalPages);

        // remove overflowing pages and current page
        pages = pages.filter((page) => page >= 1 && page <= totalPages);

        // remove duplicates
        pages = [...new Set(pages)];

        return pages;
    }, [currentPage, totalPages]);

    return {
        handleNavBack,
        handleNavForward,
        isActiveLeft,
        isActiveRight,
        setPage,
        tiles,
    };
};
