import React, { useContext } from 'react';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { Typography, Icon } from '@vaimo-int/aem-pwa-common-components';
import { CategoryContext } from '@/components/RootComponents/Category/context/categoryContext';
import { useFilterSort } from './hooks/useFilterSort';
import type { IFilterSort, ISortItem } from '../FilterBar/types';
import classes from './filterSort.module.scss';
import radioClasses from '@/components/RadioGroup/radio.module.scss';

const FilterSort = ({ handleScrollToFilterBar, options }: IFilterSort) => {
    const { search, startTransition } = useContext(CategoryContext);
    const t = useTranslations('productSort');
    const { currentSort, handleItemClick, handleOpenCloseSortBlock, isSortOpen, orderElementRef } = useFilterSort({
        handleScrollToFilterBar,
        options,
        search,
        startTransition,
    });

    if (!options?.length) return null;

    const items = options.map((sortItem: ISortItem) => {
        const { attribute, id, sortDirection, text } = sortItem;
        const isSelected = currentSort?.id === id;

        return (
            <div className={radioClasses.radio} key={`${attribute}--${sortDirection}`}>
                <label className={radioClasses.radioLabel} htmlFor={id}>
                    <input
                        id={id}
                        type="radio"
                        checked={isSelected}
                        onChange={() => handleItemClick(sortItem)}
                        value={id}
                    />
                    {text}
                </label>
            </div>
        );
    });

    return (
        <div className={clsx(classes.sortItem, isSortOpen && classes.sortItemActive)} ref={orderElementRef}>
            <button onClick={handleOpenCloseSortBlock} className={classes.sortButton}>
                <Typography.Body style={'bold'}>{t('newSortButton')}</Typography.Body>
                {currentSort && <Typography.Body>{currentSort.text}</Typography.Body>}
                <Icon name={'chevron-down'} size={18} className={classes.chevronIcon} />
            </button>
            <div className={classes.dropdown}>{items}</div>
        </div>
    );
};

export default FilterSort;
