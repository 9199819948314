import { gql } from '@apollo/client';
import {
    CategoryBreadcrumbsFragment,
    CategoryFragment,
    FiltersFragment,
    ProductsFragment,
} from './categoryFragments.gql';
import { ProductDetailsFragment } from '@/components/RootComponents/Product/api/productDetailFragment.gql';
import { ProductSwatchesFragment } from '@/components/RootComponents/Product/api/productSwatchesFragment.gql';

export const getCategoriesTree = gql`
    query GetCategories($url: String!) {
        categories(filters: { url_path: { eq: $url } }) {
            items {
                heading_1
                bottom_description
                description
                name
                image
                image_mobile
                robots_meta_tag
                display_mode
                ...CategoryBreadcrumbsFragment
                ...CategoryFragment
            }
        }
    }
    ${CategoryFragment}
    ${CategoryBreadcrumbsFragment}
`;

export const getCategoryProductsQuery = gql`
    query GetCategoryProducts(
        $currentPage: Int = 1
        $inputText: String!
        $pageSize: Int = 16
        $filters: ProductAttributeFilterInput!
        $sort: ProductAttributeSortInput
    ) {
        products(pageSize: $pageSize, currentPage: $currentPage, filter: $filters, sort: $sort, search: $inputText) {
            ...ProductsFragment
        }
    }
    ${ProductsFragment}
`;

// TODO optimize (reduce) used fields
export const getCategoryProductsClientQuery = gql`
    query GetCategoryProducts(
        $currentPage: Int = 1
        $inputText: String!
        $pageSize: Int = 16
        $filters: ProductAttributeFilterInput!
        $sort: ProductAttributeSortInput
    ) {
        products(pageSize: $pageSize, currentPage: $currentPage, filter: $filters, sort: $sort, search: $inputText) {
            items {
                id
                uid
                ...ProductDetailsFragment
                ...ProductSwatchesFragment
            }
            __typename
        }
    }
    ${ProductDetailsFragment}
    ${ProductSwatchesFragment}
`;

export const getCategoryFiltersQuery = gql`
    query GetCategoryProductsFilters(
        $currentPage: Int = 1
        $inputText: String!
        $pageSize: Int = 16
        $filters: ProductAttributeFilterInput!
        $sort: ProductAttributeSortInput
    ) {
        productSortOptions {
            text
            id
            attribute
            sortDirection
        }
        products(pageSize: $pageSize, currentPage: $currentPage, filter: $filters, sort: $sort, search: $inputText) {
            ...FiltersFragment
        }
    }
    ${FiltersFragment}
`;

export const getFilterInputsQuery = gql`
    query GetFilterInputsForCategory {
        __type(name: "ProductAttributeFilterInput") {
            inputFields {
                name
                type {
                    name
                }
            }
        }
    }
`;
