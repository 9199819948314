import React, { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';
import { SwatchesSkeleton } from '@/components/RootComponents/Category/modules/ProductCard';
import classes from '@/components/RootComponents/Category/modules/ProductsGrid/productsGrid.module.scss';

const ProductsGridSkeleton = ({ length, pageSize }: { length?: number; pageSize?: number }): ReactElement => {
    return (
        <div className={classes.skeletonProductsGrid}>
            {Array(length || pageSize || 16)
                .fill(1)
                .map((_, idx) => (
                    <div className={classes.skeletonCardContainer} key={idx}>
                        <div className={classes.skeletonLink}>
                            <div className={classes.skeletonImageContainer}>
                                <Skeleton className={classes.skeletonImage} />
                            </div>
                            <div className={classes.skeletonCardBody}>
                                <div className={classes.skeletonSwatchesContainer}>
                                    <SwatchesSkeleton />
                                </div>
                                <Skeleton height={18} />
                                <Skeleton height={20} width={120} />
                                <Skeleton height={20} width={80} />
                            </div>
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default ProductsGridSkeleton;
