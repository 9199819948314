import React from 'react';
import clsx from 'clsx';
import { ButtonSelector, Icon } from '@vaimo-int/aem-pwa-common-components';
import { SIZE_GROUP } from '@/constants/listingData';
import type { ItemType } from '@/components/RootComponents/Category/modules/FilterBar/types';

import classes from './filterItem.module.scss';
import checkboxStyles from '@vaimo-int/aem-pwa-common-components/dist/components/Checkbox/styles/checkbox.module.scss';

interface IFilterItem {
    filterState: Set<any>;
    group: string;
    handleClick: (group: string, item: ItemType) => void;
    item: ItemType;
}

const FilterItem = ({ filterState, group, handleClick, item }: IFilterItem) => {
    const { color, title, value } = item;
    const isLightColor = color && ['white', 'beige', 'yellow'].includes(color.toLowerCase());
    const isSelected = filterState?.size ? filterState.has(item) : false;

    return group === SIZE_GROUP ? (
        <ButtonSelector type="button" label={title} active={isSelected} onClick={() => handleClick(group, item)} />
    ) : (
        <label
            className={clsx(classes.filterItem, checkboxStyles.label, color && classes.filterItemColor)}
            onClick={() => handleClick(group, item)}
        >
            <input
                type={'checkbox'}
                className={clsx(checkboxStyles.field, color && checkboxStyles.fieldHide)}
                name={group}
                value={`${title}-${value}`}
                onChange={(e) => e.target.value}
                checked={isSelected}
            />
            {color && (
                <span
                    className={clsx(checkboxStyles.iconHolder, isSelected && checkboxStyles.iconHolderActive)}
                    style={{ backgroundColor: `${color}` }}
                >
                    <Icon
                        className={clsx(
                            checkboxStyles.icon,
                            isSelected && checkboxStyles.iconActive,
                            isLightColor && checkboxStyles.iconLightColor,
                        )}
                        name={'checkmark'}
                        size={'18px'}
                    />
                </span>
            )}
            {title}
        </label>
    );
};

export default FilterItem;
