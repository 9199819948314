import { useHhRouter } from '@/utils/useHhRouter';

export const useHistory = () => {
    const router = useHhRouter();

    return {
        push: (path: string) => {
            router.push(path);
        },
    };
};
