import { useCallback, useMemo, useState, useEffect } from 'react';
import { useAppContext } from '@/lib/context';
import throttle from 'lodash.throttle';
import useIsDesktop from '@/hooks/useIsDesktop';
import { getFiltersFromSearch } from '@/components/RootComponents/Category/modules/FilterBlock/utils/helpers';
import actionsTagManager from '@/integrations/google-tag-manager/src/helpers/actions';
import { PRODUCT_GALLERY_REF, FILTER_BAR_REF } from '@/constants/refIds';
import type { IFilter } from '@/components/RootComponents/Category/types';
import type { ISearchParams } from '@/types/page';

export const useFilterBar = ({ filters, search }: { filters: IFilter[]; search?: ISearchParams }) => {
    const isDesktop = useIsDesktop({ minWidth: '48.1rem', query: 'none' });
    const [
        { isFilterModalOpen, isFilterSidebarOpen },
        {
            actions: { setIsFilterModalOpen, setIsFilterSidebarOpen },
        },
    ] = useAppContext();

    const [isFilterBarVisible, setIsFilterBarVisible] = useState(true);
    const [isStickyBar, setIsStickyBar] = useState(false);
    const [prevScrollY, setPrevScrollY] = useState(0);

    useEffect(() => {
        const filterBarRefHeight = globalThis.document.getElementById(FILTER_BAR_REF)?.offsetHeight;

        if (filterBarRefHeight) {
            document.documentElement.style.setProperty('--filter-bar-height', `${filterBarRefHeight}px`);
        }
    }, []);

    const handleScrollToFilterBar = useCallback(() => {
        const galleryContent = globalThis.document.getElementById(PRODUCT_GALLERY_REF);

        if (!galleryContent) {
            return;
        }

        galleryContent.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, []);

    const toggleFilterButton = useCallback(() => {
        if (isDesktop) {
            setIsFilterSidebarOpen(!isFilterSidebarOpen);
            actionsTagManager.toggleClick(!isFilterSidebarOpen);

            return;
        }

        setIsFilterModalOpen(!isFilterModalOpen);
        actionsTagManager.toggleClick(!isFilterModalOpen);

        if (globalThis.document) {
            document.documentElement.dataset.scrollLock = 'true';
        }
    }, [isFilterSidebarOpen, isFilterModalOpen, isDesktop]);

    const totalFiltersCount = useMemo(() => {
        if (!search) return;

        const filtersFromSearch = getFiltersFromSearch(search);

        return filters?.reduce((count, item) => {
            const values = filtersFromSearch.get(item.attribute_code);

            return count + (values?.size || 0);
        }, 0);
    }, [filters, search]);

    const handleScroll = useCallback(() => {
        if (window.scrollY === prevScrollY) return;

        const filterBar = globalThis.document.getElementById(FILTER_BAR_REF);
        const { top } = filterBar?.getBoundingClientRect() || { top: 0 };
        const height = filterBar ? parseInt(getComputedStyle(filterBar).getPropertyValue('top'), 10) : 72;

        if (top <= height) {
            setIsStickyBar(true);
        } else {
            setIsStickyBar(false);
        }

        if (!window.scrollY) {
            setIsFilterBarVisible(false);
        }

        if (window.scrollY <= prevScrollY || window.scrollY < top) {
            setIsFilterBarVisible(true);
        } else if (window.scrollY >= prevScrollY) {
            setIsFilterBarVisible(false);
        }

        setPrevScrollY(window.scrollY);
    }, [prevScrollY]);

    useEffect(() => {
        const throttled = throttle(handleScroll, 500);
        window.addEventListener('scroll', throttled);

        return () => {
            window.removeEventListener('scroll', throttled);
        };
    }, [handleScroll, isDesktop]);

    return {
        handleScrollToFilterBar,
        isFilterBarVisible,
        isFilterModalOpen,
        isFilterSidebarOpen,
        isStickyBar,
        toggleFilterButton,
        totalFiltersCount,
    };
};
