'use client';

import React, { createContext, useTransition } from 'react';
import { useCategory } from '../hooks/useCategory';
import type { ICategoryContext, ICategoryProviderProps } from '../types';

export const CategoryContext = createContext<ICategoryContext>({} as ICategoryContext);

export const CategoryProvider = ({
    breadcrumbs,
    categoryId,
    children,
    isCategoryPage,
    metaTitle,
    name,
    search,
    slug,
    ...contextValues
}: ICategoryProviderProps) => {
    const [isPending, startTransition] = useTransition();
    const { categoryUrlPath, setProductsData } = useCategory({
        breadcrumbs,
        categoryId,
        isCategoryPage,
        metaTitle,
        name,
        search,
        slug,
    });

    return (
        <CategoryContext.Provider
            value={{
                breadcrumbs,
                categoryId,
                categoryUrlPath,
                isCategoryPage,
                isPending,
                metaTitle,
                name,
                search,
                setProductsData,
                startTransition,
                ...contextValues,
            }}
        >
            {children}
        </CategoryContext.Provider>
    );
};
