import { gql } from '@apollo/client';

export const CategoryFragment = gql`
    fragment CategoryFragment on CategoryTree {
        id
        meta_description
        meta_keywords
        meta_title
        is_sibling_button_black
        is_sibling_category
        show_as_subcategory_on_plp
        siblingButtons {
            name
            url_path
        }
        uid
        url_path
        children {
            is_sibling_category
            show_as_subcategory_on_plp
            include_in_menu
            name
            url_path
        }
    }
`;

export const FiltersFragment = gql`
    fragment FiltersFragment on Products {
        aggregations {
            __typename
            fhr_facet_id
            label
            count
            attribute_code
            options {
                default_label
                label
                value
                count
                swatch_data {
                    value
                    type
                }
            }
        }
    }
`;

export const ProductsFragment = gql`
    fragment ProductsFragment on Products {
        items {
            name
            color
            sku
            small_image {
                url
            }
            image {
                url
            }
            url_key
            price_range {
                maximum_price {
                    final_price {
                        value
                        currency
                    }
                    regular_price {
                        value
                        currency
                    }
                }
                minimum_price {
                    final_price {
                        value
                        currency
                    }
                    regular_price {
                        value
                        currency
                    }
                }
            }
        }
        fhr_response_id
        page_info {
            current_page
            total_pages
        }
        total_count
    }
`;

export const CategoryBreadcrumbsFragment = gql`
    fragment CategoryBreadcrumbsFragment on CategoryTree {
        breadcrumbs {
            category_id
            category_name
            category_level
            category_url_path
        }
    }
`;
