import React, { ReactElement } from 'react';
import { useTranslations } from 'next-intl';
import { Typography, Button2 as Button, Tag } from '@vaimo-int/aem-pwa-common-components';
import type { ItemType } from '../FilterBar/types';
import classes from './filterPreview.module.scss';

export interface IFilterPreview {
    filterState: Map<string, any>;
    handleClick: (group: string, item: ItemType) => void;
    handleReset: () => void;
}

const FilterPreview = ({ filterState, handleClick, handleReset }: IFilterPreview): ReactElement | null => {
    const tFilter = useTranslations('filterModal');
    const tSearch = useTranslations('searchTrigger');

    const filterElements = () => {
        const elements: ReactElement[] = [];

        for (const [group, items] of filterState) {
            for (const item of items) {
                const { label, title, value } = item || {};
                const key = `${group}::${title}_${value}`;
                const text = label || title;

                elements.push(
                    // <Link href={generatePath(group, item)} key={key} scroll={false}>
                    <Tag key={key} aria-label={label} onClick={() => handleClick(group, item)} variant={'dismissable'}>
                        {text}
                    </Tag>,
                    // </Link>,
                );
            }
        }

        return elements;
    };

    return filterState.size ? (
        <div className={classes.preview}>
            <Typography.Body className={classes.previewTitle} style={'bold'}>
                {tFilter('currentFilters.ariaLabel')}
            </Typography.Body>
            <div className={classes.previewHolder}>
                <div className={classes.previewList}>{filterElements()}</div>
                <Button
                    classes={classes.previewButton}
                    data-cy="FilterSidebar-clearButton"
                    onClick={handleReset}
                    variant="trigger"
                >
                    {tSearch('clearSearchForm')}
                </Button>
            </div>
        </div>
    ) : null;
};

export default FilterPreview;
