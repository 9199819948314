import React, { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';
import classes from '@/components/RootComponents/Category/modules/ProductsGrid/productsGrid.module.scss';

const NUMBER_OF_SWATCHES = 4;

const SwatchesSkeleton = (): ReactElement => (
    <div className={classes.skeletonSwatches}>
        {Array(NUMBER_OF_SWATCHES)
            .fill(1)
            .map((_, idx) => (
                <div key={idx} className={classes.skeletonSwatchesItem}>
                    <Skeleton className={classes.skeletonSwatchesItem} />
                </div>
            ))}
    </div>
);

export default SwatchesSkeleton;
