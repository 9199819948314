import { useAppContext } from '@/lib/context';
import type { IUseFredHopper, IFHRGetFacetsPayload, IFHRCommonData } from '../types';
import type { ISearchParams } from '@/types/page';

export const useFredHopper = (responseId: string, search?: ISearchParams): IUseFredHopper => {
    const { q: searchTerms = '' } = search || {};

    const [{ storeConfig }] = useAppContext();
    const { storeName, websiteCode } = storeConfig;

    const commonData: IFHRCommonData = {
        locale: `${storeName?.toLowerCase()}_${websiteCode?.toUpperCase()}`,
        responseId,
    };

    const getFacets = ({ filtersData, nextSearch, oldSearch }: IFHRGetFacetsPayload) => {
        if (!nextSearch || oldSearch.length >= nextSearch.length) return {};

        const oldSearchArray = oldSearch?.slice(1).split('&') || [];
        const nextSearchArray = nextSearch.slice(1).split('&');
        const [searchId, searchValue] =
            nextSearchArray
                .find((search) => !search.startsWith('p=') && !oldSearchArray.includes(search))
                ?.split('=') || [];

        const { fhr_facet_id: facetId } = filtersData.find((filter) => filter.attribute_code === searchId) || {};

        return {
            facetId,
            facetValue: searchValue,
        };
    };

    return {
        commonData,
        getFacets,
        searchTerms,
    };
};
