import { gql } from '@apollo/client';

export const ProductDetailsFragment = gql`
    fragment ProductDetailsFragment on ProductInterface {
        __typename
        color
        features
        description {
            html
        }
        id
        uid
        meta_description
        meta_keyword
        name
        price {
            regularPrice {
                amount {
                    currency
                    value
                }
            }
        }
        price_range {
            maximum_price {
                final_price {
                    value
                    currency
                }
                regular_price {
                    value
                    currency
                }
                discount {
                    amount_off
                }
            }
            minimum_price {
                final_price {
                    value
                    currency
                }
                regular_price {
                    value
                    currency
                }
                discount {
                    amount_off
                }
            }
        }
        robots_meta_tag
        sku
        small_image {
            url
        }
        stock_status
        pdf_sheet
        product_use_for {
            code
            title
        }
        url_key
    }
`;
