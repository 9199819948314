import { ISearchParams } from '@/types/page';

export const DELIMITER = '=';

export const getSearchFromState = (initialValue: string, filterKeys: Set<any>, filterState: Map<string, any>) => {
    // preserve all existing params
    const nextParams = new URLSearchParams(initialValue);

    // iterate over available filters
    for (const key of filterKeys) {
        // remove any existing filter values
        nextParams.delete(key);
    }

    // To support better Fastly caching we are preparing the URL params in a way that
    // whatever user selects the sequence is the same.
    // The sorting is done here, so that preserved params, like pagination (p), are not sorted
    // At first we sort Map keys, and below Map values by value parameter in the Set

    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    const sortedFilterState = new Map([...filterState.entries()].sort());

    // iterate over the latest filter values
    for (const [group, items] of sortedFilterState) {
        const sortedItems = new Set([...items].sort((a, b) => a.value.localeCompare(b.value)));
        for (const item of sortedItems) {
            const { value } = item || {};

            // append the new values
            nextParams.append(group, value);
        }
    }

    // prepend `?` to the final string if it is not empty
    return nextParams.toString() ? `?${nextParams}` : '';
};

export const getStateFromSearch = (initialValue: string, filterKeys: Set<any>, filterItems: Map<string, any>) => {
    // preserve all existing params
    const params = new URLSearchParams(initialValue);
    const uniqueKeys = new Set(params.keys());
    const nextState = new Map();

    // iterate over existing param keys
    for (const key of uniqueKeys) {
        // if a key matches a known filter, add its items to the next state
        if (filterKeys.has(key)) {
            const group = key;
            const items = new Set();
            const groupItemsByValue = new Map();

            // cache items by value to avoid inefficient lookups
            for (const item of filterItems.get(group)) {
                groupItemsByValue.set(item.value, item);
            }

            // map item values to items
            for (const value of params.getAll(key)) {
                const existingFilter = groupItemsByValue.get(value);

                if (existingFilter) {
                    items.add(existingFilter);
                } else {
                    console.warn(`Existing filter ${value} not found in possible filters`);
                }
            }

            // add items to the next state, keyed by group
            nextState.set(group, items);
        }
    }

    return nextState;
};

export const getFiltersFromSearch = (initialValue: string | ISearchParams) => {
    const filters = new Map();
    const invalidKeys = ['p', 'q', 'ct', 'sort'];

    if (typeof initialValue === 'string') {
        const params = new URLSearchParams(initialValue);
        const uniqueKeys = new Set(params.keys());

        // iterate over existing param keys
        for (const key of uniqueKeys) {
            // if a key matches a known filter, add its items to the next state
            if (!invalidKeys.includes(key)) {
                const group = key;
                const items = new Set();

                // map item values to items
                for (const value of params.getAll(key)) {
                    items.add(value);
                }
                const sortedItems = new Set([...items].sort((a: any, b: any) => a.localeCompare(b)));

                // add items to the next state, keyed by group
                filters.set(group, sortedItems);
            }
        }
    } else {
        const uniqueKeys = new Set(Object.keys(initialValue));

        for (const key of uniqueKeys) {
            if (!invalidKeys.includes(key)) {
                const group = key;
                const items = new Set();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const params = initialValue[key];

                if (typeof params === 'string') {
                    items.add(params);
                } else {
                    for (const value of params) {
                        items.add(value);
                    }
                }

                const sortedItems = new Set([...items].sort((a: any, b: any) => a.localeCompare(b)));

                filters.set(group, sortedItems);
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/require-array-sort-compare
    return new Map([...filters.entries()].sort());
};

export const stripHtml = (html: string) => html.replace(/(<([^>]+)>)/gi, '');

/** GetFilterInput helpers below. */
const getValueFromFilterString = (keyValueString: string) => keyValueString.split(DELIMITER).pop();

/**
 * Converts a set of values to a range filter
 * @param {Set} values
 */
const toRangeFilter = (values: Set<string>) => {
    // Range should always only be a single string. In the event we received
    // multiple, just return the first.
    const rangeString = getValueFromFilterString(Array.from(values)[0]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const [from, to] = rangeString.split('_');
    const rangeFilter = {
        from,
        to,
    };

    if (rangeFilter.from === '*') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete rangeFilter.from;
    }
    if (rangeFilter.to === '*') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        delete rangeFilter.to;
    }

    return rangeFilter;
};

/**
 * Converts a set of values into an equals filter
 * @param {Set} values
 */
const toEqualFilter = (values: Set<string>) =>
    values.size > 1 ? { in: Array.from(values).map((value) => value) } : { eq: Array.from(values)[0] };

/**
 * Converts a set of values into a match filter
 * @param {Set} values
 */
const toMatchFilter = (values: Set<string>) => {
    return { match: getValueFromFilterString(Array.from(values)[0]) };
};

const CONVERSION_FUNCTIONS = {
    FilterEqualTypeInput: toEqualFilter,
    FilterMatchTypeInput: toMatchFilter,
    FilterRangeTypeInput: toRangeFilter,
};

export const getFilterInput = (values: Set<string>, type: string | undefined) => {
    if (!type) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const conversionFunction = CONVERSION_FUNCTIONS[type];

    if (!conversionFunction) {
        throw TypeError(`Unknown type ${type}`);
    }

    return conversionFunction(values);
};

export const filterTypeMap = (introspectionData: []) => {
    const typeMap = new Map();

    if (introspectionData?.length) {
        introspectionData.forEach(({ name, type }: { name: string; type: { name: string } }) => {
            typeMap.set(name, type.name);
        });
    }

    return typeMap;
};

export const convertObjectToSearchParams = (search?: ISearchParams) => {
    const params = new URLSearchParams();

    if (search) {
        for (const [key, value] of Object.entries(search)) {
            if (Array.isArray(value)) {
                value.forEach((item) => params.append(key, item));
            } else {
                params.append(key, value);
            }
        }
    }

    return params;
};

export const getFilters = (
    categoryId: number | string | null,
    filterType: Map<string, string>,
    search: ISearchParams = {},
) => {
    const { sort = '' } = search as ISearchParams;
    const [sortParamsAttribute, sortParamsDirection] = sort?.split('-') || [];
    const filters = getFiltersFromSearch(search || '');
    const newFilters: Record<string, any> = {};

    filters.forEach((values, key: string) => {
        newFilters[key] = getFilterInput(values, filterType.get(key));
    });

    if (categoryId) {
        newFilters.category_id = { eq: String(categoryId) };
    }

    return {
        filters: newFilters,
        sort: sort ? { [sortParamsAttribute]: sortParamsDirection } : { position: 'ASC' },
    };
};
