'use client';

import React from 'react';
import { FiChevronRight as ChevronRightIcon } from 'react-icons/fi';
import Button from '@/components/Button';
import { useBackToTopButton } from './hooks/useBackToTopButton';

import classes from './styles/backToTopButton.module.scss';

export interface IBackToTopButton {
    totalPages: number;
}

const BackToTopButton = ({ totalPages }: IBackToTopButton) => {
    const { handleClick, shouldShowOnPage } = useBackToTopButton({ totalPages });

    return (
        <div className={shouldShowOnPage ? classes.rootVisible : classes.root}>
            <Button
                variant={'secondary'}
                className={classes.backToTopButton}
                onClick={handleClick}
                type="button"
                isSmall
            >
                <ChevronRightIcon className={classes.backToTopIcon} />
            </Button>
        </div>
    );
};

export default BackToTopButton;
