'use client';

import React, { useRef } from 'react';
import { Grid, ProductCard2 as Item } from '@vaimo-int/aem-pwa-common-components';
import { useProductCard } from './hooks/useProductCard';
import { useSelectProduct } from '@vaimo/google-tag-manager';
import { useFredHopper } from '@/components/RootComponents/Category/hooks/useFredHopper';
import SwatchesSkeleton from './modules/Skeleton';
import { IProductCard } from '@/components/RootComponents/Category/modules/ProductCard/types';

const ProductCard = ({
    changePagination,
    fhrId,
    index,
    isBot,
    item,
    lastProduct,
    pageSize,
    setInViewport,
    yotpoRating,
}: IProductCard) => {
    const itemRef = useRef(null);
    const { imageProps, productDetails, productPrices, showRange, swatchesProps } = useProductCard({
        changePagination,
        isBot,
        item,
        itemRef,
        setInViewport,
    });
    const { commonData } = useFredHopper(fhrId);
    const { selectItem } = useSelectProduct();

    return (
        <Grid.Col size={{ l: 4, m: 4, s: 6 }}>
            <Item
                {...productDetails}
                image={imageProps}
                isYotpoEnabled={true}
                price={{
                    ...productPrices,
                    finalPrice: productPrices.minPrice,
                    regularPrice: productPrices.maxPrice,
                    regularPriceMax: productPrices.maxPrice,
                    showRange,
                }}
                swatches={swatchesProps}
                yotpoRating={yotpoRating}
                ref={itemRef}
                SwatchesSkeleton={SwatchesSkeleton}
                dataIndex={index}
                dataLast={index % pageSize === pageSize - 1}
                dataPageIndex={index % pageSize === pageSize - 1 && !lastProduct ? (index + 1) / pageSize : null}
                onClick={() => selectItem({ fredHopper: commonData, product: item })}
            />
        </Grid.Col>
    );
};

export default ProductCard;
