import { encodeSearchParams } from '@/lib/context/appServerState/utils/settingsParser';

type TNextJsTree = [any, { children: TNextJsTree }];

const getDeepChild = (obj: TNextJsTree, depth: number) => {
    let iteratedObject = obj;
    for (let i = 1; i <= depth; i++) {
        iteratedObject = iteratedObject[1].children;
    }

    return iteratedObject;
};

/**
 * A workaround which allows to change browser location without triggering NextJs navigation.
 *
 * We should have internal NextJs history state for navigation between pages by browser actions.
 * NextJsInternalTree has to be updated based on new page data, which is reflected in the app page params.
 * Sensitive to "/app" structure.
 *
 * Browser autoscroll should be disabled as well, since navigation back will show us only items for the current page,
 * not from previous/next pages, which is used for infinite scrolling.
 *
 * Page precaching/prefetch would help for smoother/faster backward navigation: users won't need to wait for RSC payload download.
 *
 * Potentially can be replaced with server actions.
 */

export const modifyHistoryState = (pathname: string, urlSearchParams: URLSearchParams, isCategory: boolean) => {
    const plpLayout = getDeepChild(history.state.__PRIVATE_NEXTJS_INTERNALS_TREE, 6);

    if (isCategory) {
        plpLayout[0][1] = encodeSearchParams(urlSearchParams);
    } else {
        plpLayout[0] = plpLayout[0].split('?')[0] + `?${JSON.stringify(Object.fromEntries(urlSearchParams))}`;
    }

    // Since we removed all parameters in middleware, we don't need to adjust page props
    // const plpPage = getDeepChild(plpLayout, 2);
    // plpPage[0] = plpPage[0].split('?')[0] + `?${JSON.stringify(Object.fromEntries(urlSearchParams))}`;

    history.scrollRestoration = 'manual';
    window.history.replaceState(history.state, '', `${pathname}?${urlSearchParams?.toString()}`);
};
