import { useEffect, useState } from 'react';
import { usePathname } from 'next/navigation';
import { useCategoryPage as useEmarsysCategoryPage } from '@/integrations/emarsys-tracking';
import { PAGE_TYPES, useViewProductList } from '@/integrations/google-tag-manager';
import type { IUseCategory, IProductsData } from '../types';
import { useUserContext } from '@/lib/context';
import { setCurrentCategoryForBreadCrumbs } from '@/components/Breadcrumbs/utils/setCurrentCategoryForBreadCrumbs';
import { removeStoreCodeFromUrl } from '@/utils/url';

export const useCategory = ({
    breadcrumbs,
    categoryId,
    isCategoryPage,
    metaTitle,
    name,
    search,
    slug,
}: IUseCategory) => {
    const [productsData, setProductsData] = useState({} as IProductsData);
    const pathname = usePathname();
    const [{ isSignedIn }] = useUserContext();

    useEffect(() => {
        setCurrentCategoryForBreadCrumbs(removeStoreCodeFromUrl(pathname));
        const vilLastResort = sessionStorage.getItem('vil_last_resort');

        if (!vilLastResort || vilLastResort === 'positive') {
            return;
        }

        sessionStorage.setItem('vil_last_resort', 'positive');
    }, []);

    useViewProductList({
        categoryId,
        categoryPath: breadcrumbs,
        isSignedIn,
        itemListName: search?.q ? 'Search Results Page' : name,
        pageType: isCategoryPage ? PAGE_TYPES.CATEGORY : PAGE_TYPES.SEARCH_RESULTS,
        pathname,
        title: metaTitle,
        ...productsData,
    });
    useEmarsysCategoryPage({ categoryPath: breadcrumbs, pathname, searchTerm: search?.q });

    return {
        categoryUrlPath: slug || pathname,
        setProductsData,
    };
};
