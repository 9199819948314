import { useCallback } from 'react';
import { useAppContext } from '@/lib/context';
import useIsDesktop from '@/hooks/useIsDesktop';
import actionsTagManager from '@/integrations/google-tag-manager/src/helpers/actions';
import { PRODUCT_GALLERY_REF, FILTER_BAR_REF, TOP_HEADER_REF, GENERAL_HEADER_REF } from '@/constants/refIds';

export const FILTER_BAR_HEIGHT = 48;
export const GALLERY_MARGIN_TOP = 20;

export const useFilterSidebar = () => {
    const isDesktop = useIsDesktop({ minWidth: '48.1rem', query: 'none' });
    const [
        { isFilterModalOpen, isFilterSidebarOpen },
        {
            actions: { setIsFilterModalOpen, setIsFilterSidebarOpen },
        },
    ] = useAppContext();

    const handleScrollToFilterBar = () => {
        const galleryContent = globalThis.document.getElementById(PRODUCT_GALLERY_REF);
        const topHeaderHeight = window.document.getElementById(TOP_HEADER_REF)?.offsetHeight || 0;
        const generalHeaderHeight = window.document.getElementById(GENERAL_HEADER_REF)?.offsetHeight || 0;

        if (!galleryContent) {
            return;
        }

        const headerHeight = isDesktop ? generalHeaderHeight : topHeaderHeight + generalHeaderHeight;
        const filterBarHeight = globalThis.document.getElementById(FILTER_BAR_REF)?.offsetHeight || FILTER_BAR_HEIGHT;

        galleryContent.style.scrollMarginTop = `${filterBarHeight + headerHeight + GALLERY_MARGIN_TOP}px`;
        galleryContent.scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const toggleFilterButton = useCallback(() => {
        if (isDesktop) {
            setIsFilterSidebarOpen(!isFilterSidebarOpen);
            actionsTagManager.toggleClick(!isFilterSidebarOpen);

            return;
        }

        setIsFilterModalOpen(!isFilterModalOpen);
        actionsTagManager.toggleClick(!isFilterModalOpen);

        if (globalThis.document) {
            document.documentElement.dataset.scrollLock = '';
        }

        handleScrollToFilterBar();
    }, [isFilterSidebarOpen, isFilterModalOpen, isDesktop]);

    return {
        handleScrollToFilterBar,
        isFilterModalOpen,
        isFilterSidebarOpen,
        toggleFilterButton,
    };
};
