import React, { ReactElement } from 'react';
import Skeleton from 'react-loading-skeleton';
import classes from '@/components/RootComponents/Category/modules/FilterSidebar/filterSidebar.module.scss';

const FilterSidebarSkeleton = (): ReactElement => {
    const simpleAccordion = (
        <div className={classes.skeletonAccordion}>
            <div className={classes.skeletonSummary}>
                <Skeleton height={18} />
            </div>
            <div className={classes.skeletonFilterContent}>
                {Array(5)
                    .fill(1)
                    .map((_, idx) => (
                        <div key={idx}>
                            <Skeleton height={18} />
                        </div>
                    ))}
            </div>
        </div>
    );

    return (
        <>
            <div>
                <div className={classes.skeletonAccordion}>
                    <div className={classes.skeletonColorsSummary}>
                        <Skeleton height={18} />
                    </div>
                    <div className={classes.skeletonFilterColors}>
                        {Array(9)
                            .fill(1)
                            .map((_, idx) => (
                                <div className={classes.skeletonFilterColorOption} key={idx}>
                                    <Skeleton circle={true} height={40} width={40} />
                                    <Skeleton height={18} width={65} />
                                </div>
                            ))}
                    </div>
                </div>
                {simpleAccordion}
                {simpleAccordion}
                <div className={classes.skeletonAccordion}>
                    <div className={classes.skeletonSummary}>
                        <Skeleton height={18} />
                    </div>
                    <div className={classes.skeletonFilterSizes}>
                        {Array(10)
                            .fill(1)
                            .map((_, idx) => (
                                <div key={idx}>
                                    <Skeleton height={40} />
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FilterSidebarSkeleton;
