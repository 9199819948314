export const plpParams: string[] = [
    'color_group',
    'fit_type',
    'gender',
    'product_use_for',
    'season',
    'size',
    'sort',
    'p',
];
