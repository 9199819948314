import { TSettingsObject } from '@/utils/middleware/applySettings';
import { plpParams } from '@/modules/dynamic-routing/data/plpParams';
import { pdpParams } from '@/modules/dynamic-routing/data/pdpParams';

export const DEFAULT_EMPTY_SETTINGS = 'x';

export const KEY_VALUE_SEPARATOR = '~';
export const GROUPS_SEPARATOR = '.';

export const encodeSettings = (settings: TSettingsObject): string => {
    return (
        Object.entries(settings)
            .map(([key, value]) => `${key}${KEY_VALUE_SEPARATOR}${value}`)
            .join(GROUPS_SEPARATOR) || DEFAULT_EMPTY_SETTINGS
    );
};

export const decodeSettings = (settingsString: string): TSettingsObject => {
    const settings: Record<string, number> = {};
    if (settingsString === DEFAULT_EMPTY_SETTINGS) return settings as TSettingsObject;

    settingsString.split(GROUPS_SEPARATOR).forEach((item) => {
        const [key, value] = item.split(KEY_VALUE_SEPARATOR);
        settings[key] = +value;
    });

    return settings as TSettingsObject;
};

export const encodeSearchParams = (search: URLSearchParams): string => {
    if (!search?.size) return DEFAULT_EMPTY_SETTINGS;

    const paramArray: Array<string> = [];

    for (const [key, value] of search.entries()) {
        if (plpParams.includes(key)) {
            paramArray.push(`${key}${KEY_VALUE_SEPARATOR}${value}`);
        }
    }

    return paramArray.length ? paramArray.join(GROUPS_SEPARATOR) : DEFAULT_EMPTY_SETTINGS;
};

export const encodePdpSearchParams = (search: URLSearchParams): string => {
    if (!search?.size) return DEFAULT_EMPTY_SETTINGS;

    const paramArray: Array<string> = [];

    for (const [key, value] of search.entries()) {
        if (pdpParams.includes(key)) {
            paramArray.push(`${key}${KEY_VALUE_SEPARATOR}${value}`);
        }
    }

    return paramArray.length ? paramArray.join(GROUPS_SEPARATOR) : DEFAULT_EMPTY_SETTINGS;
};

export const decodeSearchParams = (searchParams?: string) => {
    const searchObject: Record<string, any> = {};

    if (!searchParams || searchParams === DEFAULT_EMPTY_SETTINGS) return searchObject;

    searchParams.split(GROUPS_SEPARATOR).forEach((item) => {
        const [key, value] = item.split(KEY_VALUE_SEPARATOR);

        if (searchObject.hasOwnProperty(key)) {
            if (Array.isArray(searchObject[key])) {
                searchObject[key].push(value);
            } else {
                searchObject[key] = [searchObject[key], value];
            }
        } else {
            searchObject[key] = value;
        }
    });

    return searchObject;
};
