import { useCallback, useEffect, useRef, useState } from 'react';
import throttle from 'lodash.throttle';
import type { IBackToTopButton } from '../backToTopButton';

export const useBackToTopButton = ({ totalPages }: IBackToTopButton) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleClick = useCallback(() => {
        document.documentElement.scrollTo({
            behavior: 'smooth',
            left: 0,
            top: 0,
        });
    }, []);

    const prevScrollY = useRef(0);

    const controlDirection = useCallback(() => {
        if (!window.scrollY) {
            setIsVisible(false);
        } else if (window.scrollY <= prevScrollY.current) {
            setIsVisible(true);
        }
        prevScrollY.current = window.scrollY;
    }, []);

    useEffect(() => {
        const throttled = throttle(controlDirection, 500);
        window.addEventListener('scroll', throttled);

        return () => {
            window.removeEventListener('scroll', throttled);
        };
    }, [controlDirection]);

    const shouldShowOnPage = totalPages > 2 && isVisible;

    return { handleClick, shouldShowOnPage };
};
