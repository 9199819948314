import React from 'react';
import clsx from 'clsx';
import { usePagination } from './hooks/usePagination';
import Link from '@/components/Link';
import type { IPagination } from './types';
import defaultClasses from './styles/pagination.module.scss';

const Pagination = ({ classes, isFirstPageExcluded = false, pageControl, urlPath }: IPagination) => {
    const { tiles } = usePagination({ ...pageControl });

    if (pageControl.totalPages === 1) {
        return null;
    }

    return (
        <div className={clsx(defaultClasses.root, classes)}>
            {tiles.map((tile) => (
                <Link
                    className={clsx(defaultClasses.tile, tile === pageControl.currentPage && defaultClasses.tileActive)}
                    key={tile}
                    href={`${isFirstPageExcluded && tile === 1 ? urlPath : `${urlPath}?p=${tile}`}`}
                >
                    {tile}
                </Link>
            ))}
        </div>
    );
};

export default Pagination;
