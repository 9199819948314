import { useEffect, useMemo, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { convertObjectToSearchParams, getSearchFromState, getStateFromSearch, stripHtml } from '../utils/helpers';
import { useSelectFilter } from '@vaimo/google-tag-manager';
import { useFredHopper } from '@/components/RootComponents/Category/hooks/useFredHopper';
import type { IFilterBlockProps, ItemType } from '@/components/RootComponents/Category/modules/FilterBar/types';

const PARAMS_LIST = ['q', 'ct'];
export const useFilterBlock = ({
    fhrId,
    filters,
    handleScrollToFilterBar,
    search,
    startTransition,
}: IFilterBlockProps) => {
    const router = useRouter();
    const pathname = usePathname();
    const [previousSearchParams, setPreviousSearchParams] = useState(convertObjectToSearchParams(search));
    const searchParams = convertObjectToSearchParams(search);
    const [filterState, setFilterState] = useState(new Map());

    const { selectFilter } = useSelectFilter();
    const { commonData, getFacets } = useFredHopper(fhrId);

    const { itemsByGroup, keys, names } = useMemo(() => {
        const names = new Map();
        const keys = new Set();
        const itemsByGroup = new Map();

        if (filters?.length) {
            for (const filter of filters) {
                const { attribute_code: group, label: name, options } = filter;

                const items = [];
                names.set(group, name);
                keys.add(group);

                for (const { count, default_label, label, value } of options) {
                    items.push({
                        color: group === 'color_group' && (default_label || label),
                        count,
                        title: stripHtml(label),
                        value,
                    });
                }
                itemsByGroup.set(group, items);
            }
        }

        return { itemsByGroup, keys, names };
    }, [filters]);

    useEffect(() => {
        const nextState = getStateFromSearch(searchParams?.toString() || '', keys, itemsByGroup);

        setFilterState(nextState);
        setPreviousSearchParams(searchParams);
    }, [keys, itemsByGroup]);

    const handleReset = () => {
        const params = convertObjectToSearchParams(search);

        Object.keys(search || {}).map((key) => {
            if (!PARAMS_LIST.includes(key)) {
                params.delete(key);
            }
        });

        params.set('p', '1');

        startTransition(() => router.push(`${pathname}?${params.toString()}`, { scroll: false }));
        handleScrollToFilterBar();
    };

    const handleClickOnItem = (group: string, item: ItemType) => {
        const nextState = new Map(filterState);
        const nextSet = new Set(filterState.get(group));
        const params = convertObjectToSearchParams(search);

        params?.set('p', '1');

        if (nextSet.has(item)) {
            nextSet.delete(item);
        } else {
            nextSet.add(item);
        }

        if (nextSet.size) {
            nextState.set(group, nextSet);
        } else {
            nextState.delete(group);
        }

        const nextSearch = getSearchFromState(params?.toString() || '', keys, nextState);

        selectFilter({
            ...commonData,
            ...getFacets({
                filtersData: filters,
                nextSearch,
                oldSearch: `?${previousSearchParams?.toString()}` || '',
            }),
        });

        startTransition(() => router.push(pathname + nextSearch, { scroll: false }));
        handleScrollToFilterBar();
    };

    return {
        filterItems: itemsByGroup,
        filterKeys: keys,
        filterNames: names,
        filterState,
        handleClickOnItem,
        handleReset,
    };
};
